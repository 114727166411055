import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { forkJoin, BehaviorSubject, catchError, map, mergeMap, Observable, of, ObservedValueOf } from 'rxjs';
import { FilterData } from '../../models/list-model';
import { ShipmentModel } from '../../models/shipment/shipment-data-model';
import { ShipmentTrackingData, ShipmentTrackingHeader } from '../../models/shipment/shipment-tracking-header-model';
import { ShipmentTrackingHistory } from '../../models/shipment/shipment-tracking-history-model';
import { RouteInformation } from '../../models/shipment/route-information-model';
import { CombinedShipment } from '../../models/shipment/combined-shipment-model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';
import { ShipmentDelayInDischargesModel } from '../../models/shipment/shipment-delay-in-discharge';
import { ShipmentDelayInTranshipmentModel } from '../../models/shipment/shipment-delay-in-transhipment';
import { RoadData } from '../../models/road-data-model';
import { RollingPlan } from '../../models/bookings/rolling-plan-model';
import { ShipmentDriftingModel } from '../../models/shipment/shipment-drifting-model';
import { IDocument } from '../../models/documents/document';
import { TransportLoad } from '../../models/shipment/transport-loads-model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {
  private apiUrl = environment.apiUrl;
  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private filterDataTransfer = new BehaviorSubject<FilterData>({});
  filterData$ = this.filterDataTransfer.asObservable();

  private filterRoadDataTransfer = new BehaviorSubject<FilterData>({});
  filterRoadData$ = this.filterRoadDataTransfer.asObservable();

  private statusFilterSubject = new BehaviorSubject<string | null>(null);
  statusFilterToggle$ = this.statusFilterSubject.asObservable();

  private sendFilter = new BehaviorSubject<string | null>(null);
  sendFilter$ = this.sendFilter.asObservable();

  private filterNameSaved = new BehaviorSubject('');
  filterNameSaved$ = this.filterNameSaved.asObservable();

  private filterNameRoadSaved = new BehaviorSubject('');
  filterNameRoadSaved$ = this.filterNameRoadSaved.asObservable();

  private sendFilterId = new BehaviorSubject<number>(-1);
  sendFilterId$ = this.sendFilterId.asObservable()

  private sendActiveFilterToFilter = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToFilter$ = this.sendActiveFilterToFilter.asObservable();

  private sendActiveFilterToFilterRoad = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToFilterRoad$ = this.sendActiveFilterToFilterRoad.asObservable();

  private sendFilterTransfer = new BehaviorSubject<FilterTransferModel>(new FilterTransferModel(-1, {}));
  sendFilterTransfer$ = this.sendFilterTransfer.asObservable();

  private sendFilterRoadTransfer = new BehaviorSubject<FilterTransferModel>(new FilterTransferModel(-1, {}));
  sendFilterRoadTransfer$ = this.sendFilterRoadTransfer.asObservable();

  private sendCustomerSelected = new BehaviorSubject<string>('');
  sendCustomerSelected$ = this.sendCustomerSelected.asObservable();

  constructor(private http: HttpClient) { }

  getShipments(filterId?: number, filterData?: FilterData, startDate?: Date | null, endDate?: Date | null): Observable<ShipmentModel[]> {
    let url = this.apiUrl + '/Shipment/GetShipments';
    let params = new HttpParams();

    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);

        url = url + '?filter=' + jsonData;
      }
    }
    else if (filterId && filterId > 0) {
      url = url + '?filterId=' + filterId.toString();
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }

    return this.http.get<ShipmentModel[]>(url, { params });
  }

  getArrivingShipments(filterId?: number, filterData?: FilterData, startDate?: Date | null, endDate?: Date | null): Observable<ShipmentModel[]> {
    let url = this.apiUrl + '/Shipment/GetArrivingShipments';
    let params = new HttpParams();

    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);

        url = url + '?filter=' + jsonData;
      }
    }
    else if (filterId && filterId > 0) {
      url = url + '?filterId=' + filterId.toString();
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }

    return this.http.get<ShipmentModel[]>(url, { params });
  }

  getShipmentsForGRRef(grRef: string | undefined): Observable<ShipmentModel[]> {
    const url = this.apiUrl + '/Shipment/GetShipmentsForGRRef?reference=' + grRef;
    return this.http.get<ShipmentModel[]>(url);
  }

  getShipmentsForGRRefContainerNo(grRef: string | null, containerNo: string | null = null): Observable<ShipmentModel> {
    const url = this.apiUrl + '/Shipment/GetShipmentsForGRRefContainerNo?grRef=' + grRef + (containerNo === null ? '' : "&containerNo=" + containerNo);
    return this.http.get<ShipmentModel>(url);
  }

  getDocumentsOutstandingPerShipmentList(filterData?: FilterData, startDate?: Date, endDate?: Date): Observable<IDocument[]> {
    var url = this.apiUrl + '/Shipment/GetDocumentsOutstandingPerShipment';
    let params = new HttpParams();


    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);

        url = url + '?filter=' + jsonData;
      }
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }

    return this.http.get<IDocument[]>(url, { params })

  }


  //getShipmentForTracking(grRef: string | undefined): Observable<ShipmentModel[]> {
  //  const url = this.apiUrl + '/Shipment/GetShipmentsForGRRef?reference=' + grRef;
  //  return this.http.get<ShipmentModel[]>(url);
  //}

  getRollingPlans(customerCode?: string): Observable<RollingPlan[]> {
    const url = this.apiUrl + '/Booking/GetRollingPlan?customerCode=' + customerCode;
    return this.http.get<RollingPlan[]>(url);
  }

  getRollingPlan(bookingHeaderCode: string, rollingPlanLineParam: string): Observable<RollingPlan[]> {
    const url = this.apiUrl + '/Booking/GetRollingPlan?bookingHeaderCode=' + bookingHeaderCode + '&lineNumber=' + rollingPlanLineParam;
    return this.http.get<RollingPlan[]>(url);
  }

  getTransportLoads(bookingHeaderCode: string, rollingPlanLineParam: string) {
    const url = this.apiUrl + '/Booking/GetTransportLoads?bookingHeaderCode=' + bookingHeaderCode + '&lineNumber=' + rollingPlanLineParam;
    return this.http.get<TransportLoad[]>(url);
  }

  getFilters(): Observable<DataFilter[]> {
    const url = this.apiUrl + '/Shipment/GetFilters';
    return this.http.get<DataFilter[]>(url);
  }

  getRoadShipmentFilters(): Observable<DataFilter[]> {
    const url = this.apiUrl + '/Shipment/GetFilters?filtlerType=9';
    return this.http.get<DataFilter[]>(url);
  }

  transferFilterData(filterData: FilterData) {
    this.filterDataTransfer.next(filterData);
    console.log("service filter data", filterData);
  }

  toggleStatusFilter(statusFilterName: string) {
    this.statusFilterSubject.next(statusFilterName);
  }

  sendFilterSetupToFilter(filter: string) {
    this.sendFilter.next(filter);
  }

  filterNameSavedSend(savedFilterName: string) {
    console.log('FilterSaved')

    this.filterNameSaved.next(savedFilterName);
  }

  filterNameSavedSendRoad(savedFilterName: string) {
    this.filterNameRoadSaved.next(savedFilterName);
  }

  sendingFilterId(filterId: number) {
    this.sendFilterId.next(filterId);
  }

  sendingActiveFilterToFilter(filter: DataFilter) {
    this.sendActiveFilterToFilter.next(filter);
  }

  sendingActiveFilterToFilterRoad(filter: DataFilter) {
    this.sendActiveFilterToFilterRoad.next(filter);
  }

  sendingFilterTransferModel(transferModel: FilterTransferModel) {
    this.sendFilterTransfer.next(transferModel);
  }

  sendingFilterTransferModelRoad(transferModel: FilterTransferModel) {
    this.sendFilterRoadTransfer.next(transferModel);
  }

  saveBookingFilter(filterName: string, filterData?: FilterData): Observable<any> {
    const url = this.apiUrl + '/Shipment/SaveFilter?filterName=' + filterName;

    let body = {};

    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        body = JSON.stringify(filteredData);
      }
    }
    console.log(url)

    return this.http.post<string>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );

  }

  saveShipmentRoadFilter(filterName: string, filterData?: FilterData): Observable<any> {
    const url = this.apiUrl + '/Shipment/SaveFilter?filterName=' + filterName + '&filtlerType=9';

    let body = {};

    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        body = JSON.stringify(filteredData);
      }
    }
    console.log(url)

    return this.http.post<string>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );

  }

  deleteShipmentFilter(filterId: number, filterName: string): Observable<any> {
    const url = this.apiUrl + '/Shipment/DeleteFilter?filterId=' + filterId;

    return this.http.delete<string>(url)
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          this.openFailSnackBar('Failed to delete Your filter ' + filterName);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        }), mergeMap(response => {
          if (response === null) {
            this.openSuccessSnackBar('Your filter, ' + filterName + ' has been deleted');
          }
          return of(response);
        })
      );

  }

  getRollingData(filterId?: number, filter?: string, customerCode?: string): Observable<RoadData[]> {
    let url = this.apiUrl + '/RoadData/GetDateRangedRoadData?customerCode=' + customerCode;
    return this.http.get<RoadData[]>(url);
  }

  sendingSelectedCustomer(customer: string) {
    this.sendCustomerSelected.next(customer);
  }


  openSuccessSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  openFailSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['snackbar-fail']
    });
  }

  getShipmentVessels(filterId?: number, filterData?: FilterData, startDate?: Date, endDate?: Date): Observable<{ [key: string]: ShipmentModel[] }> {
    const url = `${this.apiUrl}/Shipment/GetActiveShipmentsByVessel`;
    let params = new HttpParams();

    if (filterData && Object.keys(filterData).length > 0) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);
        params = params.set('filter', jsonData);
      }
    }

    if (filterId !== undefined) {
      params = params.set('filterId', filterId.toString());
    }

    if (startDate) {
      params = params.set('dateFrom', startDate.toISOString());
    }

    if (endDate) {
      params = params.set('dateTo', endDate.toISOString());
    }

    return this.http.get<{ [key: string]: ShipmentModel[] }>(url, { params });
  }

  getShipmentTrackingHeaders(
    bookingReference: string,
    containerNumber: string
  ): Observable<ShipmentTrackingHeader> {
    let url = this.apiUrl + '/Shipment/GetShipmentTrackingHeader';
    let params = new HttpParams();
    // Check if filterData is provided and contains any non-empty arrays


    // Add dateFrom if provided and not empty
    if (bookingReference) {
      params = params.set('bookingReference', bookingReference);
    }

    // Add dateTo if provided and not empty
    if (containerNumber) {
      params = params.set('containerNumber', containerNumber);
    }

    // Call the API with the updated URL and parameters
    return this.http.get<ShipmentTrackingHeader>(url, { params });
  }

  getActiveShipmentsTracking(
    filterId?: number, filterData?: FilterData, startDate?: Date | null, endDate?: Date | null
  ): Observable<ShipmentTrackingData> {
    let url = `${this.apiUrl}/Shipment/GetActiveShipmentTracking`;
    let params = new HttpParams();

    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);

        url = url + '?filter=' + jsonData;
      }
    }

    return this.http.get<ShipmentTrackingData>(url, { params });
  }

  getActiveShipmentsDrifting(
    filterData?: FilterData
  ): Observable<ShipmentDriftingModel> {
    let url = `${this.apiUrl}/Shipment/GetShipmentDrifting`;
    let params = new HttpParams();

    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);

        url = url + '?filter=' + jsonData;
      }
    }

    return this.http.get<ShipmentDriftingModel>(url, { params });
  }


  getShipmentsTrackingHistory(
    bookingReference: string,
    bookingLineReference: string
  ): Observable<ShipmentTrackingHistory[]> {
    const url = `${this.apiUrl}/Shipment/GetShipmentTrackingHistory`;
    let params = new HttpParams();

    // Add bookingReference to the query parameters
    params = params.set('bookingReference', encodeURIComponent(bookingReference));

    // Add bookingLineReference to the query parameters
    params = params.set('bookingLineReference', encodeURIComponent(bookingLineReference));

    return this.http.get<ShipmentTrackingHistory[]>(url, { params });
  }

  getShipmentRouteInformation(routeInformationMediaEditLink: string): Observable<RouteInformation> {
    const url = `${this.apiUrl}/Shipment/GetShipmentRouteInformation`;
    let params = new HttpParams();

    // Add routeInformationMediaEditLink to the query parameters
    params = params.set('routeInformationMediaEditLink', (routeInformationMediaEditLink));

    return this.http.get<RouteInformation>(url, { params });
  }

  getActiveShipment(filterId?: number, filterData?: FilterData, startDate?: Date, endDate?: Date): Observable<ShipmentModel[]> {
    const url = `${this.apiUrl}/Shipment/GetActiveShipments`;
    let params = new HttpParams();

    if (filterData && Object.keys(filterData).length > 0) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);
        params = params.set('filter', jsonData);
      }
    }

    if (filterId !== undefined) {
      params = params.set('filterId', filterId.toString());
    }

    if (startDate) {
      params = params.set('dateFrom', startDate.toISOString());
    }

    if (endDate) {
      params = params.set('dateTo', endDate.toISOString());
    }

    return this.http.get<ShipmentModel[]>(url, { params });
  }

  getShipmentDelayInDischarges(filterData?: FilterData, startDate?: Date, endDate?: Date): Observable<ShipmentDelayInDischargesModel[]> {
    let url = this.apiUrl + '/Shipment/GetShipmentDelayInDischarges';
    let params = new HttpParams();

    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);

        url = url + '?filter=' + jsonData;
      }
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }

    return this.http.get<ShipmentDelayInDischargesModel[]>(url, { params });
  }

  getShipmentDelayInTranshipment(filterData?: FilterData, startDate?: Date, endDate?: Date): Observable<ShipmentDelayInTranshipmentModel[]> {
    let url = this.apiUrl + '/Shipment/GetShipmentDelayInTranshipment';
    let params = new HttpParams();

    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);

        url = url + '?filter=' + jsonData;
      }
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }

    return this.http.get<ShipmentDelayInTranshipmentModel[]>(url, { params });
  }

}
