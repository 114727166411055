export const environment = {
  baseUrl: 'https://dev02.root2go.group',
  apiUrl: 'https://dev02.root2go.group/api',
  socketUrl: 'wss://dev02.root2go.group/ws',
  authUrl: 'https://auth.goglobal.group',
  authRealm: 'dev-goglobal',
  authClient: 'Root2Go',
  production: false,
  googleAnalyticsId: 'G-9NZP4N5GPH',
  hotjarId: 5206950,
  hotjarVersion: 6,
};
