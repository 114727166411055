<div class="filter-container" style="padding-top: 20px">
  <div class="filter-field-wrapper saved-view-name">
    <div class="filter-help">
      <img class="info-icon" alt="Info Icon" src="../../assets/Info.svg">
      <div class="help-text" *ngIf="!isSavedFilterNameEmpty">Change saved filter name for:</div>
    </div>
    <ul class="schedule-filter-fields">
      <li>Stack Status</li>
      <li>Load Port</li>
      <li>Destination Country</li>
      <li>Discharge Port</li>
      <li>Carrier</li>
    </ul>
    <strong>*Date range is not saved.</strong>
    <mat-form-field>
      <mat-label>Saved Filter View Name</mat-label>
      <input matInput [formControl]="savedFilterName">
      <mat-error *ngIf="savedFilterName.invalid && savedFilterName.touched">
        View name cannot exceed 20 characters.
      </mat-error>
    </mat-form-field>
    <div class="action-buttons">
      <button mat-button class="small-button blue" (click)="save()"
        *ngIf="!isSavedFilterNameEmpty && savedFilterName.valid">
        Save View
      </button>
    </div>
  </div>
</div>