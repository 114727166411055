import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef, ViewChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { GridsterConfig, GridType, DisplayGrid, CompactType, GridsterItem } from 'angular-gridster2';
import { ShipmentService } from '../service/shipment/shipment.service';
import { SchedulesService } from '../service/schedules/schedules.service';
import { FilterData } from '../models/list-model';
import { DocumentService } from '../service/documents/document.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { number } from 'echarts';
import { from, concatMap, Observable, of, tap, map, startWith, takeUntil, Subject, debounceTime } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { LookupModel } from '../models/lookup-model';
import { FormControl } from '@angular/forms';
import { LookupService } from '../service/lookup/lookup.service';
import { DataFilter } from '../models/filter-models';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDataComponent } from '../dialog/widget-data/widget-data.component';
import {switchMap } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { ApiUserService } from '../service/user/api-user.service';


interface DashboardEntry {
  id: number;
  filterData?: FilterData | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  title: string;
  toggle: string;
  type: string;
  order: number;
  chartType: string;
  x: number;  // X position
  y: number;  // Y position
  cols: number;  // Column span for the grid item
  rows: number;  // Row span for the grid item
  additionalFilterField?: string;
  additionalFilterValues?: [string, string];
}

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.css'], // corrected styleUrl to styleUrls
})
export class WidgetComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef<HTMLDivElement>;
  isScrollable: boolean = false;
  @ViewChild('customerMenu') customerMenu: MatMenu | undefined;
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  private destroy$ = new Subject<void>();
  @ViewChild('gridsterContainer', { static: false }) gridsterContainer!: ElementRef;
  customers = new FormControl<LookupModel[]>([]);
  customersMultiFilter = new FormControl<string>('');
  filteredCustomers!: Observable<LookupModel[]>;
  customersData: LookupModel[] = [];
  filterData: FilterData = {};
  @ViewChild('multiSelect', { static: false }) multiSelect!: MatSelect;
  @ViewChildren(MatSelect) matSelects!: QueryList<MatSelect>;
  datafilter: DataFilter = new DataFilter();
  private currentWidgetBeingFiltered: GridsterItem | null = null;


  loading: boolean = false;
  stackStatus = false
  gridOptions: GridsterConfig = {
    keepFixedHeightInMobile: true,
    gridType: GridType.Fit,
    displayGrid: DisplayGrid.OnDragAndResize,
    compactType: CompactType.None,
    mobileBreakpoint: 990, 
    pushItems: true, // Temporarily enable to allow auto-positioning
    swap: true,
    margin: 3,
    draggable: {
      enabled: false
    },
    resizable: {
      enabled: false
    },
    minCols: 1,
    maxCols: 3, // Adjust to accommodate your layout
    minRows: 5,
    maxRows: 55, // Adjust to accommodate your layout
    maxItemCols: 12,
    minItemCols: 1,
    maxItemRows: 55,
    minItemRows: 1,
    maxItemArea: 5500,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1
  };

  dashboard: GridsterItem[] = [];

 
  activeCardLoading: { [key: number]: boolean } = {};


  private dashboardEntries: DashboardEntry[] = [
    {
      id: 4,
      filterData: {eta:[]},
      dateFrom: new Date(), 
      dateTo: new Date(new Date().setDate(new Date().getDate() + 1)), 
      title: 'In the next 24 hours', 
      toggle: '', 
      type: 'Shipments',
      chartType: 'progress',
      order: 10,
      x: 0,  // X position
      y: 6,  // Y position
      cols: 1,  // Column span
      rows: 4   // Row span

    },
    {
      id: 5,
      filterData: { eta: [] },
      dateFrom: new Date(), // This will be today's date
      dateTo: new Date(new Date().setDate(new Date().getDate() + 1)), // This will be tomorrow's date
      title: 'Containers delayed in transshipment port',
      toggle: '',
      chartType: 'progress',
      type: 'Schedules',
      order: 5,
      x: 1,  // X position
      y: 6,  // Y position
      cols: 1,  // Column span
      rows: 4  // Row span
    },
    {
      id: 6,
      filterData: { eta: [] },
      dateFrom: new Date(),
      dateTo: new Date('2024-11-07'),
      title: 'Containers delayed in discharge ports',
      toggle: '',
      chartType: 'progress',
      type: 'Schedules',
      order: 6,
      x: 2,  // X position
      y: 6,  // Y position
      cols: 1,  // Column span
      rows: 4   // Row span
    },
    {
      id: 1,
      filterData: { loadPortCode: ['ZACPT'], stackStatus: ['Firm'] },
      dateFrom: new Date(),
      dateTo: null,
      title: 'Cape Town Stacks',
      toggle: 'Firm',
      type: 'Schedules',
      order: 1,
      chartType: 'cards',
      x: 0,  // X position
      y: 0,  // Y position
      cols: 1,  // Column span
      rows: 6,
      additionalFilterField: 'stackStatus',
      additionalFilterValues: ['Firm','Provisional']
    },
    {
      id: 2,
      filterData: {
        loadPortCode: ['ZADUR'], stackStatus: ['Firm']},
      dateFrom: new Date(),
      dateTo: null,
      title: 'Durban Stacks',
      toggle: 'Firm',
      type: 'Schedules',
      order: 2,
      chartType: 'cards',
      x: 1,  // X position
      y: 0,  // Y position
      cols: 1,  // Column span
      rows: 6,   // Row span
      additionalFilterField: 'stackStatus',
      additionalFilterValues: ['Firm' ,'Provisional']
    },
    {
      id: 3,
      filterData: { loadPortCode: ['ZAZBA', 'ZAPLZ'], stackStatus: ['Firm'] },
      dateFrom: new Date(),
      dateTo: null,
      title: 'Coega | PE Stacks',
      toggle: 'Firm',
      type: 'Schedules',
      order: 3,
      chartType: 'cards',
      x: 2,  // X position
      y: 0,  // Y position
      cols: 1,  // Column span
      rows: 6,   // Row span
      additionalFilterField: 'stackStatus',
      additionalFilterValues: ['Provisional','Firm']
    },
    {
      id: 7,
      filterData: { eta: [] },
      dateFrom: null,
      dateTo: null,
      title: 'ETA drifting more than 5 days',
      toggle: '',
      type: 'Shipments',
      order: 8,
      chartType: 'cards',
      x: 1,  // X position
      y: 8,  // Y position
      cols: 2,  // Column span
      rows: 6   // Row span
    },
    {
      id: 8,
      filterData: { loadPortCode: ['ZADUR'] },
      dateFrom: new Date(),
      dateTo: new Date('2024-11-13'),
      title: '',
      toggle: '',
      type: 'Map',
      order: 7,
      chartType: 'map',
      x: 4,  // X position
      y: 8,  // Y position
      cols: 1,  // Column span
      rows: 12  // Row span
    },
    {
      id: 9,
      filterData: undefined,
      dateFrom: new Date(),
      dateTo: new Date(new Date().setDate(new Date().getDate() + 4)),
      title: 'Documents outstanding',
      toggle: '',
      type: 'Documents',
      order: 9,
      chartType: 'cards',
      x: 0,  // X position
      y: 8,  // Y position
      cols: 2,  // Column span
      rows: 6   // Row span
    }
  ];
  widgetFilters: { [key: number]: { customers: FormControl<LookupModel[]>; customersMultiFilter: FormControl<string>; filteredCustomers?: Observable<LookupModel[]> } } = {};
  protected isCustUser: boolean = false;
  dataSource = new MatTableDataSource<any>();
  constructor(
    private shipmentService: ShipmentService,
    private schedulesService: SchedulesService,
    private documentService: DocumentService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private router: Router,
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private apiUserService: ApiUserService,


  ) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));

    this.matIconRegistry.addSvgIcon(
      'arrow_up_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/ArrowUpRight.svg')
    );
  }

  ngOnInit() {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;


    // Initialize the dashboard with empty Gridster items and set initial headings based on field mappings
    this.dashboard = this.dashboardEntries.map(entry => {
      const widgetId = entry.id;

      // Initialize individual filter controls for each widget
      this.widgetFilters[widgetId] = {
        customers: new FormControl<LookupModel[]>([], { nonNullable: true }),
        customersMultiFilter: new FormControl<string>('', { nonNullable: true })
      };

      // Now assign filteredCustomers to avoid referencing undefined `customersMultiFilter`
      this.widgetFilters[widgetId].filteredCustomers = this.widgetFilters[widgetId].customersMultiFilter.valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        map(searchText => {
          const filtered = this.filterCustomers(searchText || '', widgetId);
          return this.sortSelectedCustomers(filtered, this.widgetFilters[widgetId].customers.value);
        })
      );

      // Rest of your initialization for each widget item
      let headings: string[] = [];
      if (entry.id === 7) {
        const fieldMapping = {
          field1: 'shipment.vesselName',
          field2: 'shipment.voyageNumber',
          field3: 'shipment.destination ',
          field5: 'shipment.originalETA',
          field6: 'shipment.eta'
        };
        headings = this.extractFieldsForHeading(fieldMapping);
      } else if (entry.id === 1 || entry.id === 2 || entry.id === 3) {
        const fieldMapping = {
          field1: 'shipment.vesselName',
          field2: 'shipment.voyageNumber',
          field3: 'shipment.stackStart',
          field4: 'shipment.stackEnd'
        };
        headings = this.extractFieldsForHeading(fieldMapping);
      }

      return {
        id: entry.id,
        x: entry.x,
        y: entry.y,
        cols: entry.cols,
        rows: entry.rows,
        type: entry.chartType,
        chartType: entry.type,
        cardTitle: entry.title,
        cardToggle: entry.toggle,
        cards: [], // Initially empty, will populate with dynamic data
        headings: headings // Set initial headings based on field mapping
      };
    });

    // Load dynamic data for each item asynchronously
    from(this.dashboardEntries)
      .pipe(
        concatMap(entry => this.updateData(
          entry.id,
          entry.filterData || {}, // Provide an empty object if filterData is undefined
          entry.dateFrom,
          entry.dateTo,
          entry.title,
          entry.toggle,
          entry.type,
          entry.chartType,
          entry.order,
          entry.additionalFilterField,
          entry.additionalFilterValues
        ))
      )

      .subscribe({
        next: () => console.log('Update call completed'),
        error: (err) => {
          console.error('Error occurred during update:', err);
          this.loading = false;
        },
        complete: () => {
          console.log('All updateData calls completed');
          this.loading = false;
        }
      });

    this.lookupService.getCustomers({}).subscribe(data => {
      this.customersData = data;
      console.log('Loaded customers data:', this.customersData); // Check if data is loading
    });

  }
  ngAfterViewInit() {
    // Focus on input every time the filteredCustomers list changes
    this.customersMultiFilter.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.focusSearchInput();
    });

  
  }


  openCustomerMenu(): void {
    this.menuTrigger.openMenu();
  }

  focusSearchInput(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }


  getDynamicFields(data: any, fields: string[]): { [key: string]: any } {
    const result: { [key: string]: any } = {};
    fields.forEach(field => {
      result[field] = data[field] ?? 'N/A';
    });
    return result;
  }

  selectCustomerForWidget(item: GridsterItem): void {

    // Log all MatSelect IDs
    console.log('Available MatSelect IDs:');
    this.matSelects.forEach(select => {
      console.log(select.id);
    });

    // Log the ID you are searching for
    const expectedId = `mat-select-${item['id']}`;

    // Access the specific widget's selected customers
    const selectedCustomers = this.widgetFilters[item['id']].customers.value;

    // Find the MatSelect instance for this specific widget
    const selectedSelect = this.matSelects.find(select => select.id === `mat-select-${item['id']}`);
    if (selectedSelect) {
      console.log(`Found MatSelect with ID: ${expectedId}`);
      selectedSelect.close();
    } else {
      console.warn('No MatSelect found with the expected ID');
    }
 

    const widgetFieldMap: { [key: number]: string[] } = {
      5: ['eventLocation', 'daysDelayed', 'containerNo', 'bookingRefNo', 'grReferenceNo', 'customerName'],
      6: ['eventLocation', 'daysDelayed', 'containerNo', 'bookingRefNo', 'grReferenceNo', 'customerName'],
      7: ['vesselName', 'vesselVoyage', 'destination', 'originalETA', 'eta'],
      9: ['grReference', 'consignee', 'vesselName', 'voyage', 'DaysToArrival'],
    };

    if (selectedCustomers && item) {
      // Only add `customerCode` to filterData if there are selected customers
      if (selectedCustomers.length > 0) {
        const customerCodes = this.parseFilterSelections(selectedCustomers);
        item['filterData'] = { ...item['filterData'], customerCode: [customerCodes] };
      } else {
        // Remove `customerCode` from filterData if no customers are selected
        delete item['filterData'].customerCode;
      }

      this.activeCardLoading[item['id']] = true;
      const fields = widgetFieldMap[item['id']] || ['field1', 'consignee', 'vesselName', 'field4'];
 
      this.updateData(
        item['id'],
        item['filterData'],
        item['dateFrom'],
        item['dateTo'],
        item['cardTitle'],
        item['cardToggle'],
        item['chartType']
      ).subscribe({
        next: (updatedData) => {
          if (Array.isArray(updatedData) && updatedData.length > 0) {
            item['cards'] = updatedData.slice(0, 10).map(data => {
              const cardData: { [key: string]: any } = { title: item['cardTitle'] };
              fields.forEach((field, index) => {
                let fieldValue = data[field];
                if (fieldValue === '0001-01-01T00:00:00' || fieldValue === '') {
                  fieldValue = 'N/A';
                }
                if (item['id'] === 9 && field === 'DaysToArrival') {
                  const today = new Date();
                  const etaDate = data.eta ? new Date(data.eta) : null;
                  const daysToArrival = etaDate ? Math.ceil((etaDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) : 'N/A';
                  fieldValue = typeof daysToArrival === 'number' ? `${daysToArrival} days` : 'N/A';
                }
                cardData[`field${index + 1}`] = fieldValue;
              });
              return cardData;
            });
          } else {
            item['cards'] = [{ title: item['cardTitle'], field1: 'No data found' }];
          }
        },
        error: (error) => {
          console.error('Error updating widget data:', error);
          item['cards'] = [{ title: item['cardTitle'], field1: 'Error loading data' }];
        },
        complete: () => {
          this.activeCardLoading[item['id']] = false;
        }
      });
    }
  }


  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
  }


  private processFilterDataForWidget(key: string, value: string, widget: GridsterItem) {
    const dataArray = value.split(',').filter(item => item.trim() !== '');
    widget['filterData'] = { ...widget['filterData'], [key]: dataArray };
  }
  resetCustomerSelection(): void {
    this.customers.reset();
  }

  toggleCustomerSelection(customer: LookupModel): void {
    if (this.isCustomerSelected(customer)) {
      this.customers.setValue(this.customers.value?.filter(c => c.code !== customer.code) || []);
    } else {
      this.customers.setValue([...(this.customers.value || []), customer]);
    }
  }


  isCustomerSelected(customer: LookupModel): boolean {
    return (this.customers.value || []).some(c => c.code === customer.code);
  }

  private filterCustomers(searchText: string, widgetId: number): LookupModel[] {
    if (!searchText) return this.customersData;
    return this.customersData.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  createFilter(): void {
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
  }

  private parseFilterSelections(selections: LookupModel[]): string {
    return selections.map(selection => selection.code).join(',');
  }


  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;
  }



  extractFieldsForHeading(fieldMapping: { [key: string]: string }): string[] {
    return Object.values(fieldMapping).map(field => {
      // Check for the special case "GrReferrence"
      if (field === 'document.GRReferrence') {
        return 'GR' + ' ' + 'Referrence'; // Special handling for GR Referrence
      }

      // Extract the part before any `??` or `.toString()` using a regex or split
      const fieldNameMatch = field.match(/(document|schedule|shipment)\.(\w+)/);
      if (fieldNameMatch && fieldNameMatch[1]) {
        const fieldName = fieldNameMatch[2];

        // Split camelCase (e.g., VesselName -> Vessel Name)
        const formattedFieldName = fieldName.replace(/([a-z])([A-Z])/g, '$1 $2');

        // Capitalize the first letter of each word
        return formattedFieldName.replace(/\b\w/g, char => char.toUpperCase());
      }

      return 'N/A'; // Fallback if no match found
    });
  }


  openDataDialog(item: any, delays: any[], progressTitle: string): void {
    this.dialog.open(WidgetDataComponent, {
      data: { title: progressTitle, widgetData: delays }
    });
  }


  onCardInView(item: any): void {
    item.isInView = true;
  }

  updateData(id: number, filter: FilterData, dateFrom?: Date | null, dateTo?: Date | null, title: string = '', toggle: string = '', type: string = '',chartType: string = '', order?: number, toggleField?: string, toggleValues?: [string, string]): Observable<any> {
    this.activeCardLoading[id] = true;

    if (type === "Schedules") {
      if (id === 5) {
        // Use shipmentService.getShipmentDelayInTranshipment for id 5
        return this.shipmentService.getShipmentDelayInTranshipment(filter).pipe(
          tap(delays => {
            this.activeCardLoading[id] = false;

            const filteredDelays: any[] = [];
            delays.forEach(delay => {
              if (delay.daysDelayed !== undefined && delay.daysDelayed >= 3) {
                filteredDelays.push(delay);
              }
            });

            console.log('Filtered delays:', filteredDelays);

            const progressTitle = 'Containers delayed in transshipment port';
            const progressValue = filteredDelays.length; // Count of filtered delays
            const existingItem = this.dashboard.find(item => item['id'] === id);

            const formattedDateFrom: string[] | undefined = dateFrom instanceof Date && !isNaN(dateFrom.getTime())
              ? [dateFrom.toISOString()]
              : undefined;

            const formattedDateTo: string[] | undefined = dateTo instanceof Date && !isNaN(dateTo.getTime())
              ? [dateTo.toISOString()]
              : undefined;

            if (!existingItem) {
              this.addProgress(
                id,
                progressValue,
                progressTitle,
                order,
                () => this.navigateWithDetails('shipments', {
                  ...filter,
                  dateFrom: formattedDateFrom,
                  dateTo: formattedDateTo
                }),
                'custom-progress-height',
                dateFrom,
                dateTo
              );
            } else {
              existingItem['progressValue'] = progressValue;
              existingItem['cardTitle'] = progressTitle;
              existingItem['delays'] = delays;
              existingItem['progressTitle'] = progressTitle;
              existingItem['onClick'] = () => this.navigateWithDetails('shipments', {
                ...filter,
                dateFrom: formattedDateFrom,
                dateTo: formattedDateTo
              });
              existingItem['dateFrom'] = formattedDateFrom;
              existingItem['dateTo'] = formattedDateTo;
            }
          })
        );
      }

      if (id === 6) {
        // Use shipmentService.getShipmentDelayInDischarges for id 6
        return this.shipmentService.getShipmentDelayInDischarges(filter).pipe(
          tap(delays => {
            this.activeCardLoading[id] = false;

            const filteredDelays: any[] = [];
            delays.forEach(delay => {
              if (delay.daysDelayed !== undefined && delay.daysDelayed >= 2) {
                filteredDelays.push(delay);
              }
            });

            console.log('Filtered delays:', filteredDelays);

            const progressTitle = 'Containers delayed in discharge ports';
            const progressValue = filteredDelays.length; // Count of filtered delays
            const existingItem = this.dashboard.find(item => item['id'] === id);

            if (!existingItem) {
              this.addProgress(id, progressValue, progressTitle, order, () => this.openDataDialog(existingItem, filteredDelays, progressTitle), 'custom-progress-height');
            } else {
              existingItem['progressValue'] = progressValue;
              existingItem['cardTitle'] = progressTitle;
              existingItem['delays'] = filteredDelays;
              existingItem['progressTitle'] = progressTitle;
              existingItem['onClick'] = () => this.openDataDialog(existingItem, filteredDelays, progressTitle);
            }
          })
        );
      }
else {
        // For other ids (1, 2, or 3), use schedulesService.getScheduleGroupStacks
        return this.schedulesService.getScheduleGroupStacks(filter, dateFrom, dateTo).pipe(
          tap(shipments => {
            this.activeCardLoading[id] = false;

            // Sort by stackEnd in ascending order
            const sortedShipments = shipments.sort((a, b) => {
              const stackEndA = a.stackEnd ? new Date(a.stackEnd).getTime() : 0;
              const stackEndB = b.stackEnd ? new Date(b.stackEnd).getTime() : 0;
              return stackEndA - stackEndB;
            });

            const limitedShipments = sortedShipments.slice(0, 10);

            if (id === 1 || id === 2 || id === 3) {
              const fieldMapping = {
                field1: 'shipment.vesselName',
                field2: 'shipment.voyageNumber',
                field3: 'shipment.stackStart',
                field4: 'shipment.stackEnd',
              };
              const headings = this.extractFieldsForHeading(fieldMapping);

              const cardData = limitedShipments.map(shipment => ({
                title,
                field1: shipment.vesselName ?? 'N/A', 
                field2: shipment.voyageNumber ?? 'N/A',
                field3: shipment.stackStart ? this.datePipe.transform(shipment.stackStart, 'dd MMM yyyy, HH:mm') ?? 'N/A' : 'N/A',
                field4: shipment.stackEnd ? this.datePipe.transform(shipment.stackEnd, 'dd MMM yyyy, HH:mm') ?? 'N/A' : 'N/A',
              }));

              const existingItem = this.dashboard.find(item => item['id'] === id);
              if (existingItem) existingItem['cards'] = cardData;
            }
          })
        );

      }
    }

    else if (type === "Shipments") {
      this.activeCardLoading[id] = true;

      if (id === 7) {
        return this.shipmentService.getActiveShipmentsDrifting(filter).pipe(
          tap(shipments => {
            this.activeCardLoading[id] = false;

            const cardData = Array.isArray(shipments)
              ? shipments.map((shipment: any) => {
                const originalETA = new Date(shipment.originalETA);
                const eta = new Date(shipment.eta);
                const timeDifference = Math.abs(eta.getTime() - originalETA.getTime());
                const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

                if (dayDifference > 5) {
                  console.log(`Shipment ${shipment.clientReference}: Difference in days is ${dayDifference}`);
                }

                return {
                  title,
                  field1: shipment.vesselName?.toString() ?? 'N/A',
                  field2: shipment.vesselVoyage ?? 'N/A',
                  field3: shipment.destination && shipment.destination.trim() !== '' ? shipment.destination : 'N/A',
                  field5: shipment.originalETA ? this.datePipe.transform(shipment.originalETA, 'dd MMM yyyy') ?? 'N/A' : 'N/A',
                  field6: shipment.eta ? this.datePipe.transform(shipment.eta, 'dd MMM yyyy') ?? 'N/A' : 'N/A',
                  dayDifference
                };
              })
              : []; // If not an array, return an empty array

            const existingItem = this.dashboard.find(item => item['id'] === id);

            if (!existingItem) {
              console.log('not found');
              // Add the card if it does not exist
              this.addCards(title, toggle, cardData, order, id, 1, this.dashboard.find(entry => entry['id'] === id)?.['headings'] || [], 'custom-card-height');
            } else {
              console.log('found');
              existingItem['cards'] = cardData;
            }
          })
        );
      } else if (id === 4) {
        return this.shipmentService.getArrivingShipments(id, filter, dateFrom, dateTo).pipe(
          tap(shipments => {
            this.activeCardLoading[id] = false;

            const todayStart = new Date();
            todayStart.setHours(0, 0, 0, 0);
            const todayEnd = new Date();
            todayEnd.setHours(23, 59, 59, 999);

            // Count shipments arriving today
            const arrivingTodayCount = shipments.filter(shipment => {
              const etaDate = shipment.eta ? new Date(shipment.eta) : null;
              return etaDate && etaDate >= todayStart && etaDate <= todayEnd;
            }).length;

            const existingItem = this.dashboard.find(item => item['id'] === id);
            const progressTitle = 'Shipments arriving today';
            const progressValue = arrivingTodayCount;

            // Safely handle dateFrom and dateTo
            const formattedDateFrom = dateFrom instanceof Date && !isNaN(dateFrom.getTime())
              ? dateFrom
              : null;
            const formattedDateTo = dateTo instanceof Date && !isNaN(dateTo.getTime())
              ? dateTo
              : null;

            // Add or update the progress widget
            if (!existingItem) {
              this.addProgress(
                id,
                arrivingTodayCount,
                progressTitle,
                order,
                () => this.navigateWithDetails('shipments', {
                  ...filter,
                  dateFrom: undefined,
                  dateTo: undefined
                }), // Pass full filter with formatted dates
                'custom-progress-height'
              );
            } else {
              existingItem['progressValue'] = progressValue;
              existingItem['cardTitle'] = progressTitle;
              existingItem['delays'] = shipments;
              existingItem['progressTitle'] = progressTitle;
              existingItem['onClick'] = () => this.navigateWithDetails('shipments', {
                ...filter,
                dateFrom: undefined,
                dateTo: undefined
              }); // Pass full filter with formatted dates
              existingItem['dateFrom'] = formattedDateFrom;
              existingItem['dateTo'] = formattedDateTo;
            }
          })
        );
      }


    }
    else if (type === "Map") {
      this.activeCardLoading[id] = true; // Set loading to true initially

      const existingItem = this.dashboard.find(item => item['id'] === id);

      if (!existingItem) {
        // Add the map card if it does not exist
        return of(this.addMap(id, 0, 'Shipment tracking', order, () => this.navigate('/shipments', { map: true }), 'custom-map-height')).pipe(
          tap(() => {
            this.activeCardLoading[id] = false; // Set loading to false after adding
          })
        );
      } else {
        // Update the existing map item data directly
        existingItem['cardTitle'] = 'Shipment tracking';
        existingItem['progressValue'] = 0; // Set any relevant properties needed for an update
        existingItem['onClick'] = () => this.navigate('/shipments', { map: true });

        this.activeCardLoading[id] = false; // Set loading to false after updating
        return of(null); // Return observable of null since we're only updating
      }
    }

    else if (type === "Documents") {
      this.activeCardLoading[id] = false;
      return this.shipmentService.getDocumentsOutstandingPerShipmentList(filter).pipe(
        tap(documents => {
          // Limit the number of objects to 10
          const limitedDocuments = documents.slice(0, 10);

          if (id === 9) {
            const fieldMapping = {
              field1: 'document.GRReferrence',
              field2: 'document.consignee',
              field3: 'document.vesselName',
              field4: 'document.voyage',
              field5: 'document.DaysToArrival'
            };

            // Extract headings from the field mapping
            const headings = this.extractFieldsForHeading(fieldMapping);

            const cardData = limitedDocuments.map(document => {
              // Calculate the days to arrival
              const today = new Date();
              const etaDate = document.eta ? new Date(document.eta) : null;
              const daysToArrival = etaDate ? Math.ceil((etaDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) : 'N/A';

              return {
                title,
                field1: document.grReference ?? 'N/A',
                field2: document.consignee ?? 'N/A',
                field3: document.vesselName ?? 'N/A',
                field4: document.voyage ?? 'N/A',
                field5: typeof daysToArrival === 'number' ? `${daysToArrival} days` : 'N/A' // Format field4 to display days
              };
            });

            const existingItem = this.dashboard.find(item => item['id'] === id);

            if (!existingItem) {
              // Add the card if it does not exist
              this.addCards(title, toggle, cardData, order, id, 1, this.dashboard.find(entry => entry['id'] === id)?.['headings'] || [], 'custom-card-height');
            } else {
              // Update the existing card data directly
              existingItem['cards'] = cardData;
              existingItem['headings'] = headings;
              existingItem['cardTitle'] = title;
              existingItem['cardToggle'] = toggle;
            }
          }
        })
      );
    }
 else {
      return of(null);
    }
    return of(null);
  }

  isSelected(customer: any, widgetId: number): boolean {
    return this.widgetFilters[widgetId].customers.value.some((selectedCustomer: any) => selectedCustomer.code === customer.code);
  }


  clearSelectedCustomers(item: any): void {
    this.widgetFilters[item['id']].customers.setValue([]);
  }


  handleToggleChangeForCard(item: GridsterItem): void {
    // Find the original entry from dashboardEntries using the item ID
    const originalEntry = this.dashboardEntries.find(entry => entry.id === item['id']);

    if (!originalEntry) {
      console.error(`No entry found for item ID ${item['id']}`);
      return;
    }

    // Clone the original filter data to avoid direct mutation
    const updatedFilter = { ...originalEntry.filterData };

    // Add or update the stackStatus in the filter data
    updatedFilter['stackStatus'] = item['stackStatus'] ? ['Provisional'] : ['Firm'];

    // Update display toggle text
    item['cardToggle'] = item['stackStatus'] ? 'Provisional' : 'Firm';
   

    // Show loading indicator for the specific card
    this.activeCardLoading[item['id']] = true;

    // Update the data when the toggle changes
    this.updateData(item['id'], updatedFilter, originalEntry.dateFrom, originalEntry.dateTo, item['cardTitle'], '', 'Schedules')
      .subscribe(updatedData => {
        // Check if updatedData is empty and handle accordingly
        if (updatedData && Array.isArray(updatedData) && updatedData.length > 0) {
          // Replace the existing data in the item with the new data, limiting to 10 objects
          item['cards'] = updatedData.slice(0, 10).map((data: { voyageNumber: string; vesselName: string; stackStart: Date; stackEnd: Date; stackStatus: string }) => ({
            title: item['cardTitle'],
            field1: data.vesselName ?? 'N/A',
            field2: data.voyageNumber ?? 'N/A',
            field3: data.stackStart ? this.datePipe.transform(data.stackStart, 'dd MMM yyyy, HH:mm') ?? 'N/A' : 'N/A',
            field4: data.stackEnd ? this.datePipe.transform(data.stackEnd, 'dd MMM yyyy, HH:mm') ?? 'N/A' : 'N/A',
          }));
        } else {
          // Display 'No stacks found' message if no data is returned
          item['cards'] = [{
            title: item['cardTitle'],
            field1: 'No stacks found',
          }];
        }

        // Stop loading when data is updated
        this.activeCardLoading[item['id']] = false;
      });
  }



  // Update the navigateWithDetails function to ensure the filterData is correctly passed as a query parameter
  navigateWithDetails(url: string, filter: FilterData, dateFrom?: Date | null, dateTo?: Date | null): void {
    // Ensure proper handling of undefined values and date serialization
    const normalizedFilter = {
      ...filter,
      dateFrom: dateFrom ? dateFrom.toISOString() : undefined,
      dateTo: dateTo ? dateTo.toISOString() : undefined,
    };

    // Remove undefined properties from query parameters
    const queryParams: any = {
      filter: JSON.stringify(normalizedFilter), // Serialize filter data
    };

    console.log('Navigating with queryParams:', queryParams); // Debug

    // Perform navigation with queryParams
    this.router.navigate([url], {
      queryParams,
      queryParamsHandling: 'merge', // Merge with existing query params
    });
  }

  sortSelectedCustomers(customers: LookupModel[], selectedCustomers: LookupModel[]): LookupModel[] {
    // Create a set of selected customer IDs for quick lookup
    const selectedCustomerIds = new Set(selectedCustomers.map(customer => customer.code));

    // Sort function to place selected items first and sort the rest alphabetically
    return customers.sort((a, b) => {
      const isSelectedA = selectedCustomerIds.has(a.code);
      const isSelectedB = selectedCustomerIds.has(b.code);

      // If both are selected or not selected, sort by name alphabetically
      if (isSelectedA && isSelectedB) {
        return a.name.localeCompare(b.name);
      }
      if (!isSelectedA && !isSelectedB) {
        return a.name.localeCompare(b.name);
      }

      // Place selected items at the top
      return isSelectedA ? -1 : 1;
    });
  }

  navigate(url: string, queryParams?: { [key: string]: any }) {
    this.router.navigate([url], { queryParams });
  }


  addCards(
    cardTitle?: string,
    cardToggle?: string,
    cardData?: { title: string, field1: string, field2: string, field3: string, field4?: string, field5?: string, field6?: string }[],
    order: number = 0,
    id: number = 0,
    cols: number = 1,
    headings?: string[],
    cssClass: string = '',
    x: number = 0,
    y: number = 0,

  ) {
    const selectId = `mat-select${id}`; // Unique select ID based on item ID
    const item: GridsterItem = {
      cols: cols,
      rows: 2,
      y: y,
      x: x,
      type: 'cards',
      cardTitle,
      cardToggle,
      stackStatus: false,
      cards: cardData,
      id: id,
      headings: headings,
      cssClass,
      selectId
    };
    this.dashboard.push(item);
  }


  addProgress(id: number = 0, value: number, title: string, order: number = 0, onClick?: () => void, cssClass: string = '', dateFrom: Date | null | undefined = new Date(), dateTo: Date | null | undefined = new Date()) {
    const item: GridsterItem = {
      cols: 1,
      rows: 1,
      isInView: false,
      y: order,
      x: 0,
      id: id,
      type: 'progress',
      progressValue: value,
      cardTitle: title,
      onClick,
      cssClass,
      dateFrom,
      dateTo
    };
    this.dashboard.push(item);
  }

  addMap(id: number = 0, value: number, title: string, order: number = 0, onClick?: () => void, cssClass: string = '') {
    const item: GridsterItem = {
      cols: 1,
      rows: 2,
      y: order, // Set y position based on order
      x: 0,
      type: 'map',
      id: id,
      progressValue: value,
      cardTitle: title,
      onClick,
      cssClass
    };
    this.dashboard.push(item);
  }

}
